import { Message } from '../../../framework/src/Message';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
export function GenericApiCall(contentType:string,method:string,endPoint:string,token?:string,body?:string|FormData) {
    let header:any = {
      token : token ?  token : undefined
    }
      if(contentType){
        header['Content-Type']= contentType;
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      if(body){requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      )};
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      return requestMessage;
}

export function checkTokenError(responseJson:any){
  let msg: string[] = Object.values(responseJson.errors[0]);
  if(msg[0].includes("Invalid token") || msg[0].includes("Token has Expired")){
    return {type:'token',msg:`${msg[0]}. Please login again`}
  }
  else{
    return {type:'normal',msg:`${msg[0]}`}
  }
  }