import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  IconButton,
  // Customizable Area Start
  Grid,
  OutlinedInput,
  Typography,
  InputAdornment,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { ArrowBack, CheckCircle, Error, Visibility, VisibilityOff } from "@material-ui/icons";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",

    },
  },
  typography: {
    h1: {
      color: 'green',
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 600,

      color: "red"
    },
    body2: {
      fontSize: '0.7em',
    },
    subtitle1: {
      margin: "20px 0px",
    },

  },
  overrides: {
    MuiOutlinedInput: {
      root: {

        outline: '1px solid #DDE3E8'
      },
      input: {
        padding: '10.5px',
      }
    },
    MuiSvgIcon: {
      root: {
        '&.passwordIcons': {

          height: "16px", width: "16px",
        }
      },
    },
    MuiInputBase: {
      input: {
        height: '2.1em',
        padding: '10.5px',
        "&::placeholder": {
          fontSize: "14px",
          color: '#647488',

        },
        "&:focus": {


          outline: '1px solid #DDE3E8',
          borderRadius: '2px',
        },
      },
    },
    MuiContainer: {
      root: {
        '&.MuiContainer-root-1 MuiContainer-maxWidthSm-5': {
          paddingLeft: '0px !important',
          paddingRight: '0px !important'
        },
        '&.MuiInputBase-fullWidth': {
          width: '90%'
        }
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: '0.8em',
        margin: '12 0 0',
        borderRadius: '8px',

        '&.chnagePasswordBtn': {
          color: 'white',
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',

          fontWeight: '700',
          padding: '12',
        },
        '&.MuiButton-fullWidth': {
          width: '90%'
        }
      }
    },
    MuiIconButton:{
      root:{
        marginRight:'-6px !important'
      }
    },
  },
});

// Customizable Area End

import SettingsController, {
  Props
} from "./SettingsController";
export default class ChangePassword extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"} style={{ padding: 0, backgroundRepeat: 'no-repeat',height: '100vh',overflowY:'hidden', backgroundColor: '#F1F5F9', backgroundSize: '100% 100%'}}>
          <Grid container spacing={1} style={{ borderBottomRightRadius: '20px', margin: '0px -8px 0px', backgroundColor: '#FFFFFF' }}>
            <Grid item xs style={{ flexGrow: 0, padding: '4px 8px 10px', }}>
              <IconButton style={{ paddingLeft: '-1px' }} onClick={this.navigateBack} data-test-id="backArrowBtn">
                <ArrowBack style={{ height: '24px', width: '24px', color: "black", }} />
              </IconButton>
            </Grid>
            <Grid sm xs={8} item style={{ display: 'grid', padding: '4px 8px 10px', alignItems: 'center' }}>
              <Typography style={{ fontSize: '0.8em', textTransform: 'uppercase', fontWeight: 700, textAlign: 'center' }}>{'Change Password'}</Typography>
            </Grid>
            <Grid xs item container style={{ padding: '4 8 24', justifyItems: 'center', display: 'grid' }}>
            </Grid>
          </Grid>
          <Box sx={webStyle.mainWrapper}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
              <form style={webStyle.form}>
                <InputLabel style={{ fontWeight: '700', fontSize: '0.8em', margin: '18 0 8', marginLeft: '16px' }}>
                  Old Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  value={this.state.oldPassWord}
                  type={this.state.showOldPassword ? 'text' : 'password'}
                  name="oldPassWord"
                  id="oldPassWord"
                  style={{ margin: '0px 0px 5px', color: '#0F172A', backgroundColor: 'white', marginLeft: '16px', width: '90%' }}
                  data-test-id='oldpasswordInput'
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  onChange={(event) => this.setoldPassWord(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.updatePasswordVisibility('old')}
                        edge="end"
                      >
                        {this.state.showOldPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <InputLabel style={{ fontSize: '0.8em', fontWeight: '700', margin: '24 0 8', marginLeft: '16px' }}>
                  New Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  name="newPassword"
                  value={this.state.newPassword}
                  type={this.state.showNewPassword ? 'text' : 'password'}
                  id="newPassword"
                  style={{ color: '#0F172A', margin: '0px 0px 5px', backgroundColor: 'white', marginLeft: '16px', width: '90%' }}
                  data-test-id='newPasswordInput'
                  onFocus={() => this.validateNewPassword('focus')}
                  onBlur={() => this.validateNewPassword('blur')}
                  onChange={(event) => this.setNewPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.updatePasswordVisibility('new')}
                        edge="end"
                      >
                        {this.state.showNewPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <InputLabel style={{ fontSize: '0.8em',fontWeight: '700', margin: '24 0 8', marginLeft: '16px' }}>
                  Confirm New Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  name="confirmPassword"
                  value={this.state.confirmNewPassword}
                  type={this.state.showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  style={{ color: '#0F172A', margin: '0px 0px 5px', backgroundColor: 'white', marginLeft: '16px', width: '90%' }}
                  data-test-id='confirmPasswordInput'
                  onChange={(event) => this.setConfirmPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.updatePasswordVisibility('confirm')}
                        edge="end"
                      >
                        {this.state.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {this.state.showNewPasswordValidation && <Grid style={{ margin: '12px 0px' }} item>
                  <Box sx={{ minHeight: "60px", width: "100%", }}>
                    <Box sx={{ color: '#0F172A', fontWeight: '400', fontSize: '0.8em', margin: '16 0 8' }}>Your password must contain</Box>
                    {this.state.passwordErrors.map((item:{error:string,isValid?:boolean, msg:string}, index: number) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "5px",
                            flexDirection: "row",
                            flexWrap: "no-wrap",
                            alignItems: "center",
                            fontFamily: `"Lato",sans-serif`,
                          }}
                          key={index}>
                          <Box sx={{ marginRight: "5px" }}>
                            {item.isValid ? <CheckCircle style={{ color: '#34D399', fontSize: '12px' }} /> : <Error fontSize="small" style={{ color: "#DC2626" }} />}
                          </Box>
                          <Box sx={{ fontSize: 12, }}>{item.msg}</Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>}
                <Button
                  data-test-id="changePasswordBtn"
                  type="submit"
                  variant="contained"
                  className="chnagePasswordBtn"
                  style={webStyle.submit}
                  fullWidth
                  onClick={this.sendPasswordChangeRequest}
                >
                  Change Password
                </Button>
              </form>
              {this.state.changePasswordErr &&
                <Box sx={webStyle.alertBox} className="alert-box">
                  {this.state.changePasswordErr}
                </Box>
              }
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  mainWrapper: {
    fontFamily: "Inter",
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    height: '80%',
    alignItems: 'center'
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  submit: {
    height: '4.2em',
    margin: theme.spacing(3, 0, 5),
    marginLeft: '16px',
    color: "white",
  },
  alertBox: {
    padding: '8px 12px',
    backgroundColor: '#FEE2E2',
    fontSize: '0.7em',
    borderLeft: '4px solid red',
    borderRadius: '4px',
    width: '90%',
    margin: '12px 0',
    color: 'red',
  }
};
// Customizable Area End