Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.title1 = "Sell or Buy products";
exports.title2 = "Sell / Buy products";
exports.title3 = " Buy products";
exports.title4 = "Sell products";
exports.onboardingData = [
  {
    title: 'Welcome to InstaNIL',
    body: 'A Cutting-Edge, On-Demand Advertising and Content Sharing Platform InstaNIL originated to support NIL athletes but transformed into an on-demand advertising and content sharing platform. Users can instantly broadcast content from their cell phones to screens, showcasing everything from high school highlights to college and professional athletes. Advanced AI filtering ensures secure sharing, establishing InstaNIL as a trusted option for general advertising, educational institutions, and fans looking to share moments on screens in our network.',
    imgPath: 'onBoarding_one'
  },
  {
    title: 'Getting Started with InstaNIL',
    body: '<b>Download and Sign Up: </b>Sign up on the InstaNIL appProvide user data and payment preferences<br><span style="display: block; height: 0.5em;"></span>' +
      '<b>Select Location and Screen:</b>Choose a screen location or scan QR codes<br><span style="display: block; height: 0.5em;"></span>' +
      '<b>Upload Content:</b>Select images, videos, or text Set display duration<br><span style="display: block; height: 0.5em;"></span>' +
      '<b>AI Content Filtering: </b>AI ensures content meets standards, InstaNIL is a trusted platform for all users<br><span style="display: block; height: 0.5em;"></span>' +
      '<b>Content Goes Live:</b>Once uploaded content passes AI check, content is live across screens.',
    imgPath: 'onBoarding_two',
  },
  {
    title: 'Why Choose InstaNIL?',
    body: `
  <ul style="margin: 0; padding: 0 0 0 15px;">
    <li style="margin-top: -45px;">Tailored for NIL Athletes: Designed to support athletes at all levels in monetizing their name, image, and likeness.</li>
    <li style="margin-top: -20px;">Instant Content Broadcasting: Share your content faster across our network of screens, bypassing traditional manual processes.</li>
    <li style="margin-top: -20px;">AI Ensures Safety: Real-time AI content filtering ensures platform safety for various uses, from advertising to personal shoutouts.</li>
    <li style="margin-top: -20px;">All Content Types Welcome: Ideal for advertisers, fans sharing moments, and athletes promoting achievements.</li>
  </ul>
`,
    imgPath: 'onBoarding_three',
  },
  {
    title: 'InstaNIL is not just an app',
    body:' It’s a vibrant community connecting athletes, fans, and advertisers in a novel, interactive environment. Whether your goal is to monetize your NIL efforts, engage with your fan base, or broadcast content to a broad audience, InstaNIL provides the necessary tools and platform. Join today to be part of the revolution in on-demand digital advertising and content sharing.<b>\n Ready to share?</b>',
    imgPath: 'onBoarding_four',
  },
  {
    title: 'InstaNIL helps your Ad go live',
    body: 'Use your mobile devices to post Text, Photos, Photos with text, and video/short commercials to TVs/Screens',
    imgPath: 'onBoarding_three',
  }
]

exports.subtitle1 = "Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle2 = "Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native";
exports.subtitle3 = "Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle4 = "Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
// Customizable Area End