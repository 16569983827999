import React from "react";

import {
  // Customizable Area Start
  Container,
  Typography,
  IconButton,
  Grid,
  Divider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
    overrides:{
        MuiIconButton: {
           root: {
            
             fontSize: '0.8em',
             textTransform: 'none',
             margin: '0',
             '&.backArrowBtn':{
               paddingLeft:'0px !important',
             }
           }
         }
       }
});
// Customizable Area End

import CardDetailsController, {
  Props
} from "./CardDetailsController";
import { ArrowBack } from "@material-ui/icons";

export default class AddNewCard extends CardDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
         <Container maxWidth={"sm"} style={{padding:'0'}} >
        <Grid container spacing={1} style={{margin:'8px 0px', }}>
        <Grid item xs style={{flexGrow:0,padding:'8px 0px 8px 16px',}}>
          <IconButton className="backArrowBtn" onClick={this.navigateBack} data-test-id="backArrowBtn">
            <ArrowBack style={{height:'24px',width:'24px', color:'black'}}/>
          </IconButton>
        </Grid>
          <Grid  sm xs={8} item style={{display:'grid',padding:'8px 8px ',alignItems:'center'}}>
            {/* <Typography style={{fontSize:'0.8em',textTransform:'uppercase',fontWeight:700,textAlign:'center'}}>{'Change Password'}</Typography> */}
          </Grid>
          <Grid  xs item container style={{padding:'8 8 ',justifyItems:'center',display:'grid'}}>
          </Grid>
        </Grid>
          {/* <Box sx={webStyle.mainWrapper}> */}
          <Typography style={{margin:'16 16 32', fontSize:'2em',fontWeight:700}}>Card Details</Typography>
          <Typography style={{margin:'0 16 8',fontSize:'0.9em', fontWeight:700,color:'#64748B',textTransform:'uppercase'}}>enter card details</Typography>
          <Divider />

          {/* </Box> */}
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    alignItems: "center",
    fontFamily: "Roboto-Medium",
    justifyContent:'center',
    flexDirection: "column",
    
    
    paddingBottom: "30px",
    display: "flex",
  },
  
};
// Customizable Area End
