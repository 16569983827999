import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {GenericApiCall} from '../../utilities/src/GenericApiCall'
import { setStorageData,getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  privacy_policy:boolean;
  privacy_policy_data:{key:number,description:string}[];
  tokenn: string;
  linkified_terms: string;
  isChecked:boolean;
  isError:boolean;
  errorMsg:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  privacyPolicyApiCallId : string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      privacy_policy:false,
      privacy_policy_data:[],
      tokenn:'',
      linkified_terms: '',
      isChecked:false,
      isError:false,
      errorMsg:'',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getPrivacyPolicy()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson){
        if(apiRequestCallId == this.privacyPolicyApiCallId){
      let itemArray=[]
      for(let index=0;index<responseJson.length;index++){
        const termsText = responseJson[index].description;
        const linkifiedText = this.linkifyText(termsText);
        itemArray.push({
          key:index,
          description:linkifiedText
        })
      }
      this.setState({
        privacy_policy_data:itemArray
      })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  linkifyText = (text: string) => {
    let newText =text.replace("InstaNIL.com", '<a href="http://instanil.com/" target="_blank" style="color: white">InstaNIL.com</a>');
    return newText;
  }
  async componentDidMount(){
    super.componentDidMount()
    let token = localStorage.getItem("token");
    this.setState({tokenn: token ? token: ''})
    let isCheck = await getStorageData('pnps')
    this.setState({isChecked:isCheck === 'True' ? true :false})

  }
  getPrivacyPolicy=()=>{
    let token = localStorage.getItem("token")||undefined;
      let requestMesg = GenericApiCall(configJSON.exampleApiContentType,configJSON.getApiMethodType,configJSON.PrivacyPolicyApiEndPoint,token)
      this.privacyPolicyApiCallId = requestMesg.messageId;
      runEngine.sendMessage(requestMesg.id,requestMesg)
  }
  setPrivacyPolicy=(checked:boolean)=>{
    this.setState({privacy_policy:checked})
  }
  handleCheck=()=>{
    this.setState((prevState)=>({
      isChecked:!prevState.isChecked,
      isError:prevState.isChecked ? prevState.isError:false
    }))
  }
  navigateBack=()=>{
    this.props.navigation.goBack()
  }
  handleAgreeClick=()=>{
    if(this.state.isChecked){
    setStorageData('pnps',"True")
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(getName(MessageEnum.NavigationTargetMessage), "Signuplogin");
    msgs.addData(
      getName(MessageEnum.NavigationPropsMessage),
     this.props
    )
    this.send(msgs);
    this.setState({
      isError:false
    })
  }
  else{
    this.setState({
      isError:true
    })
  }
}
  // Customizable Area End
}
