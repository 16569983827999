import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {GenericApiCall} from '../../utilities/src/GenericApiCall'
import { setStorageData,getStorageData} from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  terms_and_conditions:boolean;
  terms_and_condition_data:{key:number,description:string}[];
  token:string;
  linkified_terms: string;
  isChecked:boolean;
  errorMsg:string;
  isError:boolean;
  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsandconditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  termsAndConditionsApiCallId: string= '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)

      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isChecked:false,
      errorMsg: "",
      terms_and_conditions:false,
      terms_and_condition_data:[],
      token:'',
      linkified_terms: '',
      isError:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getTermsAndConditions()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if(responseJson){
        if(apiRequestCallId == this.termsAndConditionsApiCallId){
      let itemArray=[]
      for(let index=0;index<responseJson.length;index++){
        const termsText = responseJson[index].description;
        const linkifiedText = this.linkifyText(termsText);
        itemArray.push({
          key:index,
          description:linkifiedText
        })
      }
      this.setState({
        terms_and_condition_data:itemArray
      })
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  linkifyText = (text: string) => {
    let newText =text.replace("InstaNIL.com", '<a href="http://instanil.com/" target="_blank" style="color: white">InstaNIL.com</a>');
    return newText;
  }
   
   async componentDidMount(){
    super.componentDidMount()
    let token = localStorage.getItem("token");
    this.setState({token: token ? token: ''})
    let isCheck = await getStorageData('tncs')
    this.setState({isChecked:isCheck === 'True' ? true :false})
  }
  getTermsAndConditions=()=>{
    let token = localStorage.getItem("token")|| undefined;
      let requestMesg = GenericApiCall(configJSON.exampleApiContentType,configJSON.getApiMethodType,configJSON.termsAndConditionsApiEndPoint,token)
      this.termsAndConditionsApiCallId = requestMesg.messageId;
      runEngine.sendMessage(requestMesg.id,requestMesg) 
  }
  handleCheckBox=()=>{
    this.setState((prevState)=>({
      isChecked:!prevState.isChecked,
      isError:prevState.isChecked ? prevState.isError:false
    }))
  }
 
  navigateBack=()=>{
    this.props.navigation.goBack()
  }
  handleAgreeClickBtn=()=>{
    if(this.state.isChecked){
    setStorageData('tncs',"True")
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(getName(MessageEnum.NavigationTargetMessage), "Signuplogin");
    msgs.addData(
      getName(MessageEnum.NavigationPropsMessage),
     this.props
    );
    this.send(msgs);
    this.setState({
      isError:false
    })
  }
  else{
    this.setState({
      isError:true
    })
  }
}
  // Customizable Area End
}
