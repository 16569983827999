Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Terms and Conditions";
exports.labelBodyText = `Terms and conditions outline what users can and cannot do with your website, products, and services. They lay out the rules to protect you in case of misuse and enable you to take action if it becomes necessary.
It’s also referred to by other names such as terms of service (ToS) and terms of use (ToU). Even though they have different names, in fact – there is no difference. 
In order to use your website, products, or services, your customers usually must agree to abide by your terms and conditions first.`;

exports.btnExampleTitle = "CLICK ME";
exports.termsAndConditionsApiEndPoint = 'bx_block_terms_and_conditions/terms_and_conditions/terms_condition_after_login';
exports.PrivacyPolicyApiEndPoint = 'bx_block_privacy_policy/privacy_policy/privacy_policy_after_login'

// Customizable Area End