import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  FormControlLabel,
  Drawer,
  Collapse,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { IOSSwitch } from '../../utilities/src/customComponents'
import { CheckCircle, ChevronRight, Close, Error, } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        height: '1.2px'
      }
    },
    MuiDrawer: {
      root: {
        '&.confirmationAlert div:nth-child(3)': {
          borderTopRightRadius: '35px',
        },

      }

    },
    MuiButton: {
      root: {
        fontSize: '1em',
        textTransform: 'none',
        borderRadius: '8px',
        margin: '12 0 0',
        fontWeight: 700,
        '&.conatinedBtn': {
          color: 'white',
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
          padding: '12',
          marginTop: '12px',
          marginBottom: '16px'
        },
        '&.textBtn': {
          marginTop: '24px'
        }
      }
    },
    MuiCollapse: {
      root: {
        '&.alertCollapse': {
          position: "fixed",
          bottom: '16%',
          left: '3%',
          width: '94%',
          display: 'flex',
          justifyContent: 'center'
        },
        '&.alertCollapse > div > div > div': {
          backgroundColor: 'white',
          borderRadius: '8px',
          padding: '8px 8px',
          boxShadow: '0px 4px 7px grey',
          fontSize: '0.9em',
          color: '#0F172A'
        }
      }
    },
    MuiContainer: {
      root: {
        '&.MuiContainer-root-1': {
          paddingLeft: '0px !important',
          paddingRight: '0px !important'
        }
      }
    }
  }
});
// Customizable Area End

import SettingsController, {
  Props,
  configJSON,
} from "./SettingsController";
import BottomNavigationBar from "../../hamburgermenu/src/HamburgerMenu.web";

export default class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMenu = (listToRender: any) => {
    return (listToRender?.map((item: any, index: number) => {
      return (
        <>
          <Divider key={`${item.title}${index}divider`} style={{ height: '1.2px' }} />
          <ListItem key={`${item.title}${index}`} button data-test-id={`${item.title}btn`} onClick={() => this.gotoPage(item.path, item.title)} style={{ padding: '8 -1', }}>
            <ListItemIcon key={`icon${item.title}${index}`} style={{ minWidth: '30px' }}>
              <img alt={item.title} src={this.getIconForMenu(item.icon)} style={{ height: '24px', width: '24px' }} />
            </ListItemIcon>
            <ListItemText key={`text${item.title}${index}`} style={{ fontSize: '1em', fontWeight: 400, color: '#0F172A' }} >
              <span style={{ padding: '0' }}>{item.title}</span><br />
              <span style={{ padding: '0', color: '#64748B', fontSize: '0.9em', fontWeight: 400 }}>{item.subHeader}</span>
            </ListItemText>
            {item.rightIcon ? <ListItemIcon key={`${item.title}${index}icon`} style={{ minWidth: '10px' }}><ChevronRight /></ListItemIcon> : null}

          </ListItem>
          {listToRender.length - 1 === index ? <Divider style={{ height: '1.2px' }} /> : null}
        </>)
    }))
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BottomNavigationBar navigation={this.props.navigation} fromPage="settings">
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"} style={{ padding: 0, height: '100%', position: 'relative', top: '70' }}>
            <Box sx={webStyle.mainWrapper}>
              <Typography style={{ fontSize: '2em', fontWeight: 700, padding: '0 0 20px 5vw', position: 'fixed', backgroundColor: 'white', width: '100%', zIndex: '99', marginTop: '-4px' }}>Settings</Typography>
              <div style={{ marginTop: '15px' }}>
                <ListSubheader inset style={{ paddingLeft: '5vw', fontSize: '0.9em', fontWeight: 700, textTransform: 'uppercase', marginTop: '24px', zIndex: 0 }}>Account</ListSubheader>
                {this.renderMenu(this.state.accountsList)}

                <ListSubheader inset style={{ paddingLeft: '5vw', fontSize: '0.9em', fontWeight: 700, textTransform: 'uppercase', marginTop: '24px', zIndex: 0 }}>Order details</ListSubheader>
                {this.renderMenu(this.state.orderList)}

                <ListSubheader inset style={{ paddingLeft: '5vw', fontSize: '0.9em', fontWeight: 700, textTransform: 'uppercase', marginTop: '24px', zIndex: 0 }}>Notifications</ListSubheader>
                {this.state.notificationSettingsList.map((item: any, index: number) => {
                  return (
                    <>
                      <Divider key={`${item.title}${index}divider`} style={{ height: '1.2px' }} />
                      <ListItem key={`${item.title}${index}`} style={{ padding: '8 -1', }}>
                        <ListItemText key={`text${item.title}${index}`} style={{ fontSize: '1em', fontWeight: 400, color: '#0F172A' }} >
                          <span style={{ padding: '0' }}>{item.title}</span><br />
                        </ListItemText>
                        <FormControlLabel
                          key={`control${item.title}${index}`}
                          data-test-id={`${item.value}btn`}
                          style={{ margin: '0px' }}
                          label=''
                          control={<IOSSwitch checked={item.value === 'update' ? this.state.weeklyUpdates : this.state.specialOffers} onChange={() => this.manageAlertPopUp(item.value)} name="checkedB" />}
                        />
                      </ListItem>
                      {configJSON.notifications.length - 1 === index ? <Divider style={{ height: '1.2px' }} key={`${item.title}${index}seperator`} /> : null}
                    </>)
                })}
                <ListSubheader inset style={{ paddingLeft: '5vw', fontSize: '0.9em', fontWeight: 700, textTransform: 'uppercase', marginTop: '24px', zIndex: 0 }}>Account actions</ListSubheader>
                {this.state.accountActionList.map((item: any, index: number) => {
                  return (
                    <>
                      <Divider key={`${item.title}${index}divider`} style={{ height: '1.2px' }} />
                      <ListItem key={`${item.title}${index}`} button data-test-id={`${item.actionToTake}btn`} style={{ padding: '8 -1', }} onClick={() => this.manageAlertPopUp(item.actionToTake)}>
                        <ListItemIcon key={`icon${item.title}${index}`} style={{ minWidth: '30px' }}>
                          <img alt={item.title} src={this.getIconForMenu(item.icon)} style={{ height: '24px', width: '24px' }} />
                        </ListItemIcon>
                        <ListItemText key={`text${item.title}${index}`} style={{ fontSize: '1em', fontWeight: 400, color: '#0F172A' }} >
                          <span style={{ padding: '0', color: item.actionToTake == 'delete' ? '#DC2626' : '#0F172A' }}>{item.title}</span><br />
                        </ListItemText>
                      </ListItem>
                      {configJSON.accountActions.length - 1 === index ? <Divider key={`${item.title}${index}seperator`} style={{ marginBottom: '5%', height: '1.2px' }} /> : null}
                    </>)
                })}
              </div>
              <Collapse className="alertCollapse" data-test-id="alert-notification" in={this.state.showErrMsg} >
                <Alert icon={this.state.errMessage.type == 'error' ? <Error style={{ color: 'red' }} fontSize="inherit" /> : <CheckCircle style={{ color: '#34D399' }} fontSize="inherit" />} severity={this.state.errMessage.type == 'error' ? "error" : 'success'}  >
                  {this.state.errMessage.msg}
                </Alert>
              </Collapse>
            </Box>

            <Drawer data-test-id="confirmation-alert" className="confirmationAlert" anchor={'bottom'} open={this.state.showAlert} onClose={() => this.closeAlertPopUp()} >
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="text" data-test-id="closeBtn" style={{ margin: '20px 3px', padding: '0px' }} onClick={() => this.closeAlertPopUp()}><Close style={{ fontSize: '2em' }} /></Button>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', padding: '0px 24px 24px' }}>

                <Typography component="div" style={{ fontSize: '1.1em', fontWeight: 700, margin: '12 0 0', color: '#0F172A' }}>
                  {this.state.alertData.title}
                </Typography>
                <Typography component="div" style={{ fontSize: '0.96em', fontWeight: 400, margin: '8 0', color: '#0F172A' }}>
                  {this.state.alertData.text}
                </Typography>
                {this.state.alertData.buttons.map((button: { action: string, name: string, type: "text" | "outlined" | "contained" | undefined, class: string }, index: number) => {
                  return (
                    <Button
                      key={button.action}
                      data-test-id={`${button.action}Btn`}
                      className={button.class}
                      fullWidth
                      variant={button.type}
                      onClick={() => this.performActionOnAccount(button.action)}
                    >
                      {button.name}
                    </Button>
                  )
                })}
              </Box>
            </Drawer>
          </Container>
        </ThemeProvider>
      </BottomNavigationBar>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "14%",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "42px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
