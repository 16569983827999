// src/components/Help.tsx

import React from 'react';
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Container,
	Box,
	Button,
	InputLabel,
	styled, AppBar,
	Grid,
	OutlinedInput,
	Typography, } from '@material-ui/core';
import {
	FaArrowLeft,
	FaSearch
} from "react-icons/fa";

import AdManagerController, { Props } from '../../../blocks/admanager/src/AdManagerController.web';
import { getStorageData } from "../../../framework/src/Utilities";

import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from '../../../framework/src/RunEngine';

export const configJSON = require("../config");


const theme = createTheme({
	palette: {
	  primary: {
		main: "#fff",
		contrastText: "#fff",
	  },
	},
	typography: {
	  h1: {
		fontWeight: 'bold',
		color: 'green'
	  },
	  h5: {
		fontWeight: 600,
		color: "red"
	  },
	  h6: {
		fontWeight: 500,
	  },
	  subtitle1: {
		margin: "20px 0px",
	  },
	  body2: {
		fontSize: '0.7em',
	  },
	},
	overrides: {
	  MuiSvgIcon: {
		root: {
		  '&.passwordIcons': {
			width: "18px",
			height: "18px",
			color:'#94A3B8',
		  }
		},
	  },
	  MuiInputBase: {
		input: {
		  padding: '10.5px',
		  "&::placeholder": {
			fontSize: "1em",
		  },
		  "&:focus": {
  
			borderRadius: '2px',
		  },
		},
  
	  },
	  MuiOutlinedInput: {
		root: {
		  borderRadius: '8px',
		  outline: "1px solid #CBD5E1",
		  height: '3.6em',
		  fontSize: '1em'
		},
		input: {
		  height: '100%',
		  padding: '0px 10.5px',
		  fontSize: '1em'
		}
	  },
	  MuiDrawer: {
			root: {
				'&.noInternetAlert > .MuiPaper-root': {
					borderTopRightRadius: '25px',
					backgroundColor:'black'
				},
			}
	  },
	  MuiButton: {
			root: {
				fontSize: '1em',
				textTransform: 'none',
				borderRadius: '8px',
				margin: '12 0 0',
				'&.proceedBtn': {
					color:'#1E293B',
					background: 'linear-gradient(#FFFFFF 100%,#919191 100%)',
					padding: '12',
					fontWeight: '700',
				},
			}
	  }
	},
});

const styles = (theme: Theme) =>
	createStyles({
		root: {
			color: '#ffffff', // Text color
			height: '100vh',
		},
		title: {
			marginTop: theme.spacing(8),
			paddingLeft: theme.spacing(2),
			paddingBottom: "10px",
			fontWeight: 'bold',
		},
		list: {
			padding: theme.spacing(2),
			color: '#CBD5E1'
		},
		listItem: {
			'&:not(:last-child)': {
				marginBottom: theme.spacing(1),
			},
		},
		listItemIcon: {
			color: '#CBD5E1',
		},
		nested: {
			paddingLeft: theme.spacing(4),
		},
		listItemRightIcon: {
			color: '#CBD5E1',
			display: 'flex',
  		justifyContent: 'flex-end',

		}
});

class ChangeEmail extends AdManagerController {

  updatePasswordApiID: string = '';

	handleBack = () => {
		this.props.navigation?.goBack();
	}

	handlePage=(pageName:string)=>{
		this.props.navigation.navigate(pageName)
	}

	handleUpdateEmail = async () => {
		const token = await getStorageData("token");
		const header = {
			"Content-Type": "application/json",
			token
		};

		const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
		requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
		requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), '/account_block/accounts/change_email_address');
		requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'PATCH');
		requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({
			"email": this.state.emailInput
		}));

    runEngine.sendMessage(requestMessage.id, requestMessage);
		this.updatePasswordApiID = requestMessage.messageId;
		
	}

	async receive(from: string, message: Message) { 
		let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

		const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

		if(apiRequestCallId == this.updatePasswordApiID){
			this.handleUpdateEmailResponse(responseJson)
		}
	}

	validatePassword = (validationType: string) => {
		if(validationType == 'blur' && this.state.emailInput?.length == 0) {
      this.setState({ enableField: true })
    } 
	}

	handleEmailInput = (textValue: string) => {
		this.setState({emailInput: textValue})
	}

	handleUpdateEmailResponse=(responseJson:any)=>{
		
		if(responseJson.errors){ this.setState({errorMessage: responseJson.errors}) }
		else{
			this.props.handleMessage && this.props.handleMessage(responseJson?.message || '')
			this.props.handlePageState && this.props.handlePageState('');
		}
	}
	
	renderHeader = () => {
		return (
			<StyledHeader>
				<FaArrowLeft data-test-id="go-back" onClick={this.handleBack} />
				<Typography data-test-id="search_data" className="pageTitle" onClick={() => { }}>
					{configJSON.headerChangePassword}
				</Typography>
				<FaSearch data-test-id="searchIcon" />
			</StyledHeader>
		);
	};

	render() {
		return (
			<Box style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}>
				<ThemeProvider theme={theme}>
					<div style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',position:'fixed',backgroundRepeat: 'no-repeat',backgroundSize: '100% 100%',width:'100vw'}}>
						<Container maxWidth={"sm"} style={{ height: '100vh',overflow:'scroll' }}>
						<Box sx={webStyle.mainWrapper}>
						
							<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', marginTop:'20px'}}>
							
								<form style={webStyle.form}>
									<InputLabel style={{ color:'#E2E8F0',fontSize: '1em', fontWeight: '700', margin: '18 0 8' }}>
										Email
									</InputLabel>
									<OutlinedInput
										fullWidth
										required
										name="email"
										value={this.state.emailInput}
										type={'email'}
										id="newEmail"
										placeholder="Your new email"
										style={{ margin: '0px 0px 5px', color: '#CBD5E1',background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}
										data-test-id='newPasswordInput'
										onBlur={() => this.validatePassword('blur')}
										onChange={(event) => this.handleEmailInput(event.target.value)}
										
									/>
									{this.state.enableField && <span style={{color: 'red'}}>Email is required</span>}
									
									{this.state.errorMessage &&
										<Box sx={webStyle.alertBox} className="alert-box">
										{this.state.errorMessage}
										</Box>
									}
									
									<Button
										data-test-id="submitBtn"
										className="proceedBtn"
										variant="contained"
										style={webStyle.submit}
										fullWidth
										onClick={this.handleUpdateEmail}
									>
										Change Email
									</Button>
								</form>
							</Box>
						</Box>
						</Container>
					</div>
				</ThemeProvider> 

				{/* <NewPassword navigation={this.props.navigation} /> */}
			</Box>
		);
	}
}

export default withStyles(styles)(ChangeEmail);

const StyledHeader = styled(AppBar)({
	width: '100%',
	display: "flex",
	flexDirection: 'row',
	justifyContent: "space-between",
	background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
	boxShadow: "none",
	alignItems: "centner",
	padding: "20px 16px 16px 20px",
	color: "#FFF",
	"& .pageTitle": {
		fontSize: "16px",
		fontWeight: 700,
		lineHeight: "22px",
	},
	borderBottom: "1px solid #64748B",
});

const webStyle = {
  logoe: {
    filter: 'invert(1)',
    marginTop: "80px",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    paddingBottom: "30px",
    height: '80%',
    alignItems: 'center'
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  submit: {
    // margin: theme.spacing(3, 0, 5),
    background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
    color:'#1E293B',
  },
  alertBox: {
    backgroundColor: '#FEE2E2',
    padding: '8px 12px',

    borderLeft: '4px solid red',
    borderRadius: '4px',
    fontSize: '0.9em',

    margin: '12px 0',
    width: '90%',
    color: 'red',
  }
};
