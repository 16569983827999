Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "splashscreen1";
exports.labelBodyText = "splashscreen1 Body";
exports.loaderText = 'Taking your business to next level..'
exports.btnExampleTitle = "CLICK ME";
exports.timeout = 10000;
// Customizable Area End