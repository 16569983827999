Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
  exports.uploadPictureAlertButtons = [
    {name:'Take photo',action:'click'},
    {name:'Photo library',action:'upload'},
    {name:'Remove photo',action:'remove'},
  ]
  exports.getUserProfileApiEndPoint = '/account_block/accounts/get_profile'
  exports.updateUserProfileApiEndPoint = '/account_block/accounts/update_profile'
// Customizable Area End
