Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "PUT";
exports.exampleAPiEndPoint = "/account_block/screen_managers/dashboard_details";
exports.transactionAPiEndPoint = "/account_block/screen_managers/recent_transactions";
exports.exampleAPiMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdManager";
exports.labelBodyText = "AdManager Body";
exports.btnExampleTitle = "CLICK ME";
exports.filterList = [{ title: 'Last 7 days', key: '7_days'}, { title: 'Last month', key: 'last_month'}, { title: 'Last year', key: 'last_year'}, { title: 'Last three years', key: 'last_3_year'}]

exports.headerHelpTxt = "HELP";

exports.headerSettingTxt = "SETTING";
// Customizable Area End
