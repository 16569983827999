import React from "react";

import {
  Dialog,
  Typography,
  IconButton,
  // Customizable Area Start
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Button,
  Box,
  Container,
  Collapse,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { QrScanner } from "@yudiel/react-qr-scanner";
import QRCode from "react-qr-code";
import PublicIcon from "@material-ui/icons/Public";
import LinkIcon from "@material-ui/icons/Link";
import { MdContentCopy } from "react-icons/md";
import ShareIcon from "@material-ui/icons/Share";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close'; 
import { TransitionProps } from "@material-ui/core/transitions";
import { notFound } from "./assets";
import { Alert } from "@material-ui/lab";
import { CheckCircle } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
   
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  overrides:{
    MuiCollapse: {
      root: {
        '&.alertCollapse': {
          position: "fixed",
          bottom: '8vh',
          width: '85%',
          display: 'flex',
          justifyContent: 'center'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116': {
          width: '80% !important'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116 > .MuiCollapse-wrapperInner-117': {
          width: '84%'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116 > .MuiCollapse-wrapperInner-117 > div': {
          backgroundColor: 'white',
          padding: '8px 16px',

          borderRadius: '8px',
          color: '#0F172A',
          fontSize: '1em',
          boxShadow: '0px 4px 7px grey',
        },
      },
    },
    MuiContainer:{
      'root':{
        padding:'0px !important',
        width: '100%',
        maxWidth: '100%',
        top: '66px',
        position: 'fixed',
        background: "#000"
      }
    },
  }


});

// Customizable Area End

import QRCodeScannerController, {
  Props,
} from "./QRCodeScannerController";
import BottomNavigationBar from "../../utilities/src/BottomNavigationBar.web";


export default class QRCodeScanner extends QRCodeScannerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    console.log("this.state.result", this.state.result);

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { open } = this.state;
    return (
      // Customizable Area Start
      <Box>
        <BottomNavigationBar navigation={this.props.navigation} id={"bottomNavigation"} fromPage="home">
          <ThemeProvider theme={theme}>
            <Container style={{
              height: "100%"
            }}>
              {this.state.result === "No result" ? (
                <div>
                  <Box sx={webStyle.qrScannerHeader}>
                    <h3>SCAN QR CODE</h3>
                    <Box className="closeContainer" position="absolute" style={webStyle.headerCloseIcon}>
                      <IconButton
                        onClick={() => history.back()}
                      >
                        <CloseIcon className="closeIcon" style={{
                          color: "#FFFFFF"
                        }} />
                      </IconButton>
                    </Box>
                  </Box>
                  <canvas ref={this.canvasRef} style={{ display: 'none' }}></canvas>
                  <div ref={this.videoRef}>
                    <QrScanner 
                      onError={(error: any) => this.handleScannerError(error)}
                      onDecode={(text: any) => this.handleScan(text)} 
                      containerStyle={webStyle.containerStyle}
                      videoStyle={webStyle.videoStyle}
                      viewFinder={() => (
                        <>
                          <svg
                            width="211"
                            height="211"
                            viewBox="0 0 211 211"
                            fill="none"
                            // xmlns="http://www.w3.org/2000/svg"
                            style={{
                              top: "0px",
                              left: "0px",
                              zIndex: 1,
                              boxSizing: "border-box",
                              border: "100px solid transparent",
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <mask id="path-2-inside-1_12456_15940" fill="white">
                              <path d="M0 23C0 25.7614 2.23858 28 5 28C7.76142 28 10 25.7614 10 23H0ZM23 10C25.7614 10 28 7.76142 28 5C28 2.23858 25.7614 0 23 0V10ZM10 23V13H0V23H10ZM13 10H23V0H13V10ZM10 13C10 11.3432 11.3432 10 13 10V0C5.8203 0 0 5.8203 0 13H10Z" />
                            </mask>
                            <path d="M0 18C-2.76142 18 -5 20.2386 -5 23C-5 25.7614 -2.76142 28 0 28V18ZM10 28C12.7614 28 15 25.7614 15 23C15 20.2386 12.7614 18 10 18V28ZM18 10C18 12.7614 20.2386 15 23 15C25.7614 15 28 12.7614 28 10H18ZM28 0C28 -2.76142 25.7614 -5 23 -5C20.2386 -5 18 -2.76142 18 0H28ZM10 18C12.7614 18 15 15.7614 15 13C15 10.2386 12.7614 8 10 8V18ZM0 8C-2.76142 8 -5 10.2386 -5 13C-5 15.7614 -2.76142 18 0 18V8ZM8 10C8 12.7614 10.2386 15 13 15C15.7614 15 18 12.7614 18 10H8ZM18 0C18 -2.76142 15.7614 -5 13 -5C10.2386 -5 8 -2.76142 8 0H18ZM10 18H0V28H10V18ZM18 0V10H28V0H18ZM10 8H0V18H10V8ZM8 0V10H18V0H8ZM-10 23C-10 31.2842 -3.28429 38 5 38V18C7.76145 18 10 20.2386 10 23H-10ZM5 38C13.2843 38 20 31.2842 20 23H0C0 20.2386 2.23855 18 5 18V38ZM23 20C31.2842 20 38 13.2843 38 5H18C18 2.23855 20.2386 0 23 0V20ZM38 5C38 -3.28429 31.2842 -10 23 -10V10C20.2386 10 18 7.76145 18 5H38ZM20 23V13H0V23H20ZM-10 13V23H10V13H-10ZM13 20H23V0H13V20ZM23 -10H13V10H23V-10ZM20 13C20 16.866 16.866 20 13 20V0C5.8203 0 0 5.8203 0 13H20ZM13 -10C0.297453 -10 -10 0.297448 -10 13H10C10 11.3431 11.3431 10 13 10V-10Z" fill="#F8FAFC" mask="url(#path-2-inside-1_12456_15940)" />
                            <mask id="path-4-inside-2_12456_15940" fill="white">
                              <path d="M0 188C0 185.239 2.23858 183 5 183C7.76142 183 10 185.239 10 188H0ZM23 201C25.7614 201 28 203.239 28 206C28 208.761 25.7614 211 23 211V201ZM10 188V198H0V188H10ZM13 201H23V211H13V201ZM10 198C10 199.657 11.3432 201 13 201V211C5.8203 211 0 205.18 0 198H10Z" />
                            </mask>
                            <path d="M0 193C-2.76142 193 -5 190.761 -5 188C-5 185.239 -2.76142 183 0 183V193ZM10 183C12.7614 183 15 185.239 15 188C15 190.761 12.7614 193 10 193V183ZM18 201C18 198.239 20.2386 196 23 196C25.7614 196 28 198.239 28 201H18ZM28 211C28 213.761 25.7614 216 23 216C20.2386 216 18 213.761 18 211H28ZM10 193C12.7614 193 15 195.239 15 198C15 200.761 12.7614 203 10 203V193ZM0 203C-2.76142 203 -5 200.761 -5 198C-5 195.239 -2.76142 193 0 193V203ZM8 201C8 198.239 10.2386 196 13 196C15.7614 196 18 198.239 18 201H8ZM18 211C18 213.761 15.7614 216 13 216C10.2386 216 8 213.761 8 211H18ZM10 193H0V183H10V193ZM18 211V201H28V211H18ZM10 203H0V193H10V203ZM8 211V201H18V211H8ZM-10 188C-10 179.716 -3.28429 173 5 173V193C7.76145 193 10 190.761 10 188H-10ZM5 173C13.2843 173 20 179.716 20 188H0C0 190.761 2.23855 193 5 193V173ZM23 191C31.2842 191 38 197.716 38 206H18C18 208.761 20.2386 211 23 211V191ZM38 206C38 214.284 31.2842 221 23 221V201C20.2386 201 18 203.239 18 206H38ZM20 188V198H0V188H20ZM-10 198V188H10V198H-10ZM13 191H23V211H13V191ZM23 221H13V201H23V221ZM20 198C20 194.134 16.866 191 13 191V211C5.8203 211 0 205.18 0 198H20ZM13 221C0.297453 221 -10 210.703 -10 198H10C10 199.657 11.3431 201 13 201V221Z" fill="#F8FAFC" mask="url(#path-4-inside-2_12456_15940)" />
                            <mask id="path-6-inside-3_12456_15940" fill="white">
                              <path d="M211 23C211 25.7614 208.761 28 206 28C203.239 28 201 25.7614 201 23H211ZM188 10C185.239 10 183 7.76142 183 5C183 2.23858 185.239 0 188 0V10ZM201 23V13H211V23H201ZM198 10H188V0H198V10ZM201 13C201 11.3432 199.657 10 198 10V0C205.18 0 211 5.8203 211 13H201Z" />
                            </mask>
                            <path d="M211 18C213.761 18 216 20.2386 216 23C216 25.7614 213.761 28 211 28V18ZM201 28C198.239 28 196 25.7614 196 23C196 20.2386 198.239 18 201 18V28ZM193 10C193 12.7614 190.761 15 188 15C185.239 15 183 12.7614 183 10H193ZM183 0C183 -2.76142 185.239 -5 188 -5C190.761 -5 193 -2.76142 193 0H183ZM201 18C198.239 18 196 15.7614 196 13C196 10.2386 198.239 8 201 8V18ZM211 8C213.761 8 216 10.2386 216 13C216 15.7614 213.761 18 211 18V8ZM203 10C203 12.7614 200.761 15 198 15C195.239 15 193 12.7614 193 10H203ZM193 0C193 -2.76142 195.239 -5 198 -5C200.761 -5 203 -2.76142 203 0H193ZM201 18H211V28H201V18ZM193 0V10H183V0H193ZM201 8H211V18H201V8ZM203 0V10H193V0H203ZM221 23C221 31.2842 214.284 38 206 38V18C203.239 18 201 20.2386 201 23H221ZM206 38C197.716 38 191 31.2842 191 23H211C211 20.2386 208.761 18 206 18V38ZM188 20C179.716 20 173 13.2843 173 5H193C193 2.23855 190.761 0 188 0V20ZM173 5C173 -3.28429 179.716 -10 188 -10V10C190.761 10 193 7.76145 193 5H173ZM191 23V13H211V23H191ZM221 13V23H201V13H221ZM198 20H188V0H198V20ZM188 -10H198V10H188V-10ZM191 13C191 16.866 194.134 20 198 20V0C205.18 0 211 5.8203 211 13H191ZM198 -10C210.703 -10 221 0.297448 221 13H201C201 11.3431 199.657 10 198 10V-10Z" fill="#F8FAFC" mask="url(#path-6-inside-3_12456_15940)" />
                            <mask id="path-8-inside-4_12456_15940" fill="white">
                              <path d="M211 188C211 185.239 208.761 183 206 183C203.239 183 201 185.239 201 188H211ZM188 201C185.239 201 183 203.239 183 206C183 208.761 185.239 211 188 211V201ZM201 188V198H211V188H201ZM198 201H188V211H198V201ZM201 198C201 199.657 199.657 201 198 201V211C205.18 211 211 205.18 211 198H201Z" />
                            </mask>
                            <path d="M211 193C213.761 193 216 190.761 216 188C216 185.239 213.761 183 211 183V193ZM201 183C198.239 183 196 185.239 196 188C196 190.761 198.239 193 201 193V183ZM193 201C193 198.239 190.761 196 188 196C185.239 196 183 198.239 183 201H193ZM183 211C183 213.761 185.239 216 188 216C190.761 216 193 213.761 193 211H183ZM201 193C198.239 193 196 195.239 196 198C196 200.761 198.239 203 201 203V193ZM211 203C213.761 203 216 200.761 216 198C216 195.239 213.761 193 211 193V203ZM203 201C203 198.239 200.761 196 198 196C195.239 196 193 198.239 193 201H203ZM193 211C193 213.761 195.239 216 198 216C200.761 216 203 213.761 203 211H193ZM201 193H211V183H201V193ZM193 211V201H183V211H193ZM201 203H211V193H201V203ZM203 211V201H193V211H203ZM221 188C221 179.716 214.284 173 206 173V193C203.239 193 201 190.761 201 188H221ZM206 173C197.716 173 191 179.716 191 188H211C211 190.761 208.761 193 206 193V173ZM188 191C179.716 191 173 197.716 173 206H193C193 208.761 190.761 211 188 211V191ZM173 206C173 214.284 179.716 221 188 221V201C190.761 201 193 203.239 193 206H173ZM191 188V198H211V188H191ZM221 198V188H201V198H221ZM198 191H188V211H198V191ZM188 221H198V201H188V221ZM191 198C191 194.134 194.134 191 198 191V211C205.18 211 211 205.18 211 198H191ZM198 221C210.703 221 221 210.703 221 198H201C201 199.657 199.657 201 198 201V221Z" fill="#F8FAFC" mask="url(#path-8-inside-4_12456_15940)" />
                          </svg>
                        </>
                      )}
                      constraints={{
                        advanced: [{
                          width: { ideal: 1280 },
                          height: { ideal: 720 },
                        }],
                        width: 1280,
                        height: 720,
                        facingMode: 'environment',
                      }}
                      scanDelay={5}
                    />
                  </div>
                </div>
              ) : (
                <div className="generatedQR">
                  <Box sx={webStyle.qrScannerHeader}>
                    <h3>SCAN QR CODE</h3>
                    <Box className="closeContainer" position="absolute" style={webStyle.headerCloseIcon}>
                      <IconButton
                        data-test-id="closeQRButton"
                        onClick={this.handleCloseQR}
                      >
                        <CloseIcon className="closeIcon" style={{
                          color: "#FFFFFF"
                        }} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box style={{
                    height: "60%",
                    filter: "blur(8px)",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${this.state.capturedImg})`,
                    backgroundSize: "cover"
                  }}></Box>
                  <Box style={{
                    height: "60%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "absolute",
                    top: "0px",
                    width: "100%",
                    // backgroundImage: `url(${this.state.capturedImg})`,
                    // backgroundSize: "contain"
                  }}>
                    <Box style={{
                      border: "6px solid #fff",
                      borderRadius: "8px"
                    }}>
                      <QRCode
                        style={{ width: "20vh", height: "20vh", position: "sticky" }}
                        value={this.state.result}
                      />
                    </Box>
                    <Box style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#6200EA",
                      background: "#F0E5FF",
                      padding: "5px 12px",
                      borderRadius: "40px",
                      marginTop: "10px",
                      width: "300px",
                    }}
                    data-test-id="qr-code-text-main"
                    onClick={() => this.navigateToOtherScreen("PaymentScreen", JSON.parse(this.state.result)?.id)}
                    >
                      <PublicIcon style={{
                        color: "#6200EA",
                        marginRight: "6px"
                      }} />
                      <div className="text" style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}> 
                        https://bcforchrisbaines-427279-react.b427279.dev.eastus.az.svc.builder.cafe/screen/{this.state.result && this.state.result !== "No result" && JSON.parse(this.state.result)?.id}
                      </div>
                    </Box>
                  </Box>
                  <Dialog
                    fullScreen
                    open={open}
                    keepMounted
                    hideBackdrop // Disable the backdrop color/image
                    disableEnforceFocus // Let the user focus on elements outside the dialog
                    disableBackdropClick
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    BackdropProps={{
                      invisible: true,
                    }}
                    style={{
                      position: "fixed",
                      top: "70%",
                    }}
                    PaperProps={{
                      style: {
                        // maxHeight: "55%",
                        height: "auto",
                        borderTopRightRadius: "20px",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                      },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        background: "#000",
                        color: "#fff",
                        paddingBottom: "24px"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderBottom: "1px solid #E2E8F0",
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "700", padding: "12px 20px" }}
                        >
                          Scan QR code
                        </Typography>
                        <IconButton aria-label="close" data-test-id='closeButton' onClick={this.handleClose} style={{
                          marginRight: '14px'
                        }}>
                          <CloseIcon style={{
                            color: "#fff"
                          }} />
                        </IconButton>
                      </div>
                      <div
                        style={{
                          padding: "18px 24px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                          <Typography
                            style={{
                              fontWeight: "400",
                              color: "#fff",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            data-test-id="qr-code-text"
                          >
                            https://bcforchrisbaines-427279-react.b427279.dev.eastus.az.svc.builder.cafe/screen/{this.state.result && this.state.result !== "No result" && JSON.parse(this.state.result)?.id}
                          </Typography>
                      </div>
                      <div 
                        className="cardLinks" 
                        style={{
                          color: "#fff",
                          padding: "0px 24px 18px 24px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div 
                          style={{
                            textDecoration: "none",
                            color: "#FFF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => this.navigateToOtherScreen("PaymentScreen", JSON.parse(this.state.result)?.id)}
                          data-test-id="open-scan-link"
                        >
                          <LinkIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#94A3B8",
                            }}
                          />
                          <Typography
                            className="cardText"
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              marginLeft: "8px",
                            }}
                          >
                            Open Link
                          </Typography>
                        </div>
                      </div>
                      <div 
                        className="cardLinks" 
                        style={{
                          color: "#fff",
                          padding: "0px 24px 18px 24px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div className="linkStyle" data-test-id="copyButton" onClick={()=>this.copyText()} style={{
                          color: "#FFF",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          justifyContent: "center",
                        }}
                        >
                          <MdContentCopy
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#94A3B8",
                            }}
                          />
                          <Typography
                            className="cardText"
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              marginLeft: "8px",
                              color: "#fff"

                            }}
                          >
                            Copy the QR code Link
                          </Typography>
                        </div>
                      </div>
                      <div 
                        className="cardLinks" 
                        style={{
                          color: "#fff",
                          padding: "0px 24px 0px 24px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div className="linkStyle" data-test-id="shareButton" onClick={()=>this.shareContent()} style={{
                          color: "#FFF",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          justifyContent: "center"
                        }}>
                          <ShareIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#94A3B8",
                            }}
                          />
                          <Typography
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              marginLeft: "8px",
                              color: "#fff"
                            }}
                          >
                            Share
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Collapse className="alertCollapse" data-test-id="alert-notification" in={this.state.showCopied} >
                      <Alert 
                        icon={<CheckCircle style={{ color: '#34D399' }} fontSize="inherit" />}
                        severity={"error"}  
                      >
                        Copied!
                      </Alert>
                    </Collapse>
                  </Dialog>
                </div>
              )}
              <Dialog
                open={this.state.openInvalidQRValue}
                TransitionComponent={Transition as any}
                keepMounted
                onClose={this.handleScanAgain}
                aria-describedby="alert-dialog-invalid-qr"
                PaperProps={{ style: webStyle.dialogWrapper }}
              >
                <DialogContent dividers>
                  <div style={webStyle.dialogIcon}>
                    <img src={notFound} alt="not-found" width={40}/>
                  </div>
                  <div>
                    <DialogContentText id="alert-dialog-invalid-qr" data-test-id="invalid-qr-alert" align="center" style={webStyle.dialogContentWrapper}>
                      No Screen available for this QR Code
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions style={webStyle.deleteActionWrapper}>
                  <Button id="try-scan-again-button" style={webStyle.backButton} onClick={() => this.handleScanAgain()}>
                    Try Again
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.openCameraValidation}
                TransitionComponent={Transition as any}
                keepMounted
                onClose={this.handleCloseCamera}
                aria-describedby="alert-dialog-slide-camera"
                PaperProps={{ style: webStyle.dialogWrapper }}
              >
                <DialogContent dividers>
                  <div style={webStyle.dialogIcon}>
                    <img src={notFound} alt="not-found" width={40}/>
                  </div>
                  <div>
                    <DialogContentText id="alert-dialog-slide-camera" data-test-id="camera-required-alert" align="center" style={webStyle.dialogContentWrapper}>
                      Camera Access Required!
                    </DialogContentText>
                    <DialogContentText align="center" style={webStyle.dialogContentDesc}>
                      In order to continue with QR scan, you must allow this webpage access to your camera.
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions style={webStyle.deleteActionWrapper}>
                  <Button id="try-again-button" style={webStyle.backButton} onClick={() => this.handleCloseCamera()}>
                    Try Again
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.openCameraOverConstrained}
                TransitionComponent={Transition as any}
                keepMounted
                onClose={this.handleCloseCamera}
                aria-describedby="alert-dialog-camera"
                PaperProps={{ style: webStyle.dialogWrapper }}
              >
                <DialogContent dividers>
                  <div style={webStyle.dialogIcon}>
                    <img src={notFound} alt="not-found" width={40}/>
                  </div>
                  <div>
                    <DialogContentText id="alert-dialog-camera" data-test-id="camera-access-alert" align="center" style={webStyle.dialogContentWrapper}>
                      Unable to access the specified camera.
                    </DialogContentText>
                    <DialogContentText align="center" style={webStyle.dialogContentDesc}>
                      Please check your device settings or try a different camera.
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions style={webStyle.deleteActionWrapper}>
                  <Button id="try-again-setting-button" style={webStyle.backButton} onClick={() => this.handleCloseCamera()}>
                    Try Again
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.openCameraError}
                TransitionComponent={Transition as any}
                keepMounted
                onClose={this.handleCloseCamera}
                aria-describedby="alert-dialog-access-camera"
                PaperProps={{ style: webStyle.dialogWrapper }}
              >
                <DialogContent dividers>
                  <div style={webStyle.dialogIcon}>
                    <img src={notFound} alt="not-found" width={40}/>
                  </div>
                  <div>
                    <DialogContentText id="alert-dialog-access-camera" data-test-id="camera-access-error-alert" align="center" style={webStyle.dialogContentWrapper}>
                      An error occurred while accessing the camera.
                    </DialogContentText>
                    <DialogContentText align="center" style={webStyle.dialogContentDesc}>
                      Please check your device settings or try a different camera.
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions style={webStyle.deleteActionWrapper}>
                  <Button id="try-again-access-button" style={webStyle.backButton} onClick={() => this.handleCloseCamera()}>
                    Try Again
                  </Button>
                </DialogActions>
              </Dialog>
            </Container>
          </ThemeProvider>
        </BottomNavigationBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "15%",
    '@media (max-height:700px)': {
      // backgroundColor:'red',
      marginBottom:'20%'
    },
  },
  hello:{
    overflow:' hidden !important',
    position: 'relative !important',
    width: '100% !important',
    paddingTop: '100vh !important',
  },
  helloDiv :{
    top: '0px !important',
    left: '0px !important',
    Zindex: '1 !important',
    boxSizing: 'border-box !important',
    position: 'absolute !important',
    width: '100% !important',
    height: '100% !important',
  },
  helloDivVideo :{
    top: '0px !important',
    left: '0px !important',
    display: 'block !important',
    position: 'absolute !important',
    overflow: 'hidden !important',
    width: '100% !important',
    height: '100% !important',
    objectFit: 'cover !important',
  },
  helloDivSvg: {
    display: 'none',
  },
  
  helloDivDivSvg: {
    display: 'flex',
    position: 'relative',
    top: '72%',
    alignItems: 'center',
    alignSelf: 'center !important',
    zIndex: '9999 !important',
    background: 'url("./assets/image_flashlight_on.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(245, 245, 245, 0.15)',
    backdropFilter: 'blur(10px)',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    marginLeft: 'calc(50% - 25px)',
    marginRight: 'calc(50% - 25px)',
  },
  
  helloDivDivSvgPath: {
    display: 'none',
  },
  helloone :{
    display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
  },
  heading :{
  position: 'absolute',
  Zindex: '11',
  top: '8.5%',
  color: 'white',
  fontWeight: '700',
  fontFamily: '"Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif',
  },
  closeContainer :{
  position: 'absolute',
    Zindex: '111',
  right: '5%',
   top: '8%',
   backgroundColor: 'var(--overlay, #010101CC)', 
  },
  closeIcon :{
  color: 'red',
  position: 'absolute',
  zIndex: '111',
  right: '5%',
    top: '8%',
    transform: 'scale(1.3)',
    cursor:'grab',
  },
  torchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position:'absolute',
    zIndex: '1',
    backgroundColor: 'rgba(245, 245, 245, 0.15)',
    backdropFilter: 'blur(10px)',
    borderRadius: '50%',
    height: '50px',
    width: '50px',
    bottom: '11%',
  },
  Torch: {
    position: 'absolute',
    zIndex: '2',
    transform: 'scale(1.1)',
  },
  
  b1: {
    position:' absolute',
    zIndex: '111',
    background: 'url("./assets/view_.png")',
    height: '30vh',
    width: '30vh',
  
    backgroundRepeat: 'no-repeat',
  },
  b2: {
    position: 'absolute',
    zIndex: '111',
    background: 'url("./assets/view_.png")',
    height: '30vh',
    width: '30vh',
    backgroundRepeat: 'no-repeat',
    transform: 'rotate(90deg)',
  },
  b3: {
    position: 'absolute',
    zIndex: '111',
    background: 'url("./assets/view_.png")',
    height: '30vh',
    width: '30vh',
    backgroundRepeat: 'no-repeat',
    transform: 'rotate(180deg)',
  },
  b4: {
    position: 'absolute',
    zIndex: '111',
    background:'url("./assets/view_.png")',
    height: '30vh',
    width: '30vh',
    backgroundRepeat: 'no-repeat',
    transform: 'rotate(270deg)',
  },
  generatedQR: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:'column',
    background: 'url("./assets/blurred.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  
  linkQr :{
    marginTop: '10px',
    display: 'flex',
    color: '#6200ea',
    justifyContent:'center',
    alignItems: 'center',
    backgroundColor: '#f0e5ff',
    width: 'auto',
    height: '30px',
    borderRadius: '50px',
  },
  text: {
    padding: '5px',
  },
  
  
  cardLinks:{
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'flex-start',
    paddingLeft: '25px',
    paddingRight: '5%',
    paddingBottom: '15px',
  },
  cardLinksa :{
    display: 'flex',
    flexDirection: 'row',
  },
  "&.MuiTypography-colorPrimary": {
    color: '#000 !important'
  },
  
  link: {
    color: 'black',
    textDecoration: 'none',
    "&:hover": {
      color: 'black',
      textDecoration: 'none'
    },
    "&:active": {
      color: 'black',
      textDecoration: 'none'
    },
  },
  linkStyle: {
    "&:visited, &:active": {
      textDecoration: 'none !important',
      color: 'black !important',
      cursor: 'pointer !important'
    },
  },
  containerStyle: {
    marginTop: 5, 
    height: 'calc(100vh - 150px)', 
    padding: '0'
  },
  qrScannerHeader: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    width: '100%',
    color: "#fff !important",
    zIndex: 99,
  },
  headerCloseIcon: {
    top: 0,
    right: "5px",
  },
  videoStyle:{ 
    height: '100%', 
    width: '100%' 
  },
  dialogWrapper: {
    borderRadius: "16px"
  },
  dialogContentWrapper: {
    fontSize: "20px",
    color: "#000"
  },
  dialogIcon: {
    dispay: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "12px",
    width: "100%",
    display: "flex",
  },
  deleteActionWrapper: {
    justifyContent: "space-between",
    padding: 0,
  },
  dialogContentDesc: {
    fontSize: "14px"
  },
  backButton: {
    margin: 0,
    padding: "12px",
    width: "100%"
  },
};
// Customizable Area End
