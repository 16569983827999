// src/components/Help.tsx

import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemIcon, Typography, styled, AppBar, Toolbar, Box, Divider } from '@material-ui/core';
import AdManagerController, { Props } from '../../../blocks/admanager/src/AdManagerController.web';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';

import MergeEngineUtilities from "../../../blocks/utilities/src/MergeEngineUtilities";
import ChangePassword from "./ManagerChangePassword.web";
import ChangeEmail from "./ManagerChangeEmail.web";
import PersonalDetails from "./ManagerPersonalDetails.web";

export const configJSON = require("../config");
const success_icon = require("../../assets/success-icon.png");

import {
	FaArrowLeft,
	FaSearch
} from "react-icons/fa";

const styles = (theme: Theme) =>
	createStyles({
		root: {
			color: '#ffffff', // Text color
			height: '100vh',
		},
		title: {
			marginTop: theme.spacing(8),
			paddingLeft: theme.spacing(2),
			paddingBottom: "10px",
			fontWeight: 'bold',
			fontSize: '16px'
		},
		list: {
			padding: theme.spacing(2),
			color: '#CBD5E1'
		},
		listItem: {
			'&:not(:last-child)': {
				marginBottom: theme.spacing(1),
			},
		},
		listItemIcon: {
			color: '#CBD5E1',
		},
		nested: {
			paddingLeft: theme.spacing(4),
		},
		listItemRightIcon: {
			color: '#CBD5E1',
			display: 'flex',
  		justifyContent: 'flex-end',

		},
		successStatusWrapper: {
			width: "90%",
			display: "flex",
			justifyContent: "center",
			margin: 'auto'
		},
		successStatus: {
			maxWidth: "300px",
			padding: "10px",
			display: "flex",
			margin: "auto",
			color: "#0F172A",
			fontSize: "16px",
			lineHeight: "24px",
			position: 'fixed',
			bottom: "20px",
			width: '80%',
			borderRadius: "8px",
			backgroundColor: "#fff",
			'& img': {
			  marginRight: "8px",
			  width: "24px"
			},
			alignItems: 'center',
			gap: '10px',
		  },
});

class Setting extends AdManagerController {

	updateEmailApiCallId: string = '';

	constructor(props: Props) {
		super(props);

		this.state = {
			...this.state,
			errorMessage: ''
		}
	}
	handleBack = () => {
		if(this.state.number.length) this.handleChangeScreen('')
		else this.props.navigation?.goBack();
	}

	handlePage=(pageName:string)=>{
		MergeEngineUtilities.navigateToScreen(pageName, this.props)
	}

	handleChangePassword = (displayAlert: boolean = false) => {
		if(displayAlert) {
			this.setState({viewAd: true});
			
			setTimeout(() => {
				this.setState({ viewAd: false });
			}, 2000);
		}
		this.setState({number: 'password'})
	}

	handleChangeScreen = (detailType: string) => {
		this.setState({number: detailType})
	}

	getHeaderTitle = () => {
		if(this.state.number === 'password') return configJSON.headerChangePassword;
		if(this.state.number === 'email') return configJSON.headerChangeEmail;
		if(this.state.number === 'details') return configJSON.headerUpdateProfile;
		return configJSON.headerSettingTxt;
	}

	getSettingPageData = () => {
		if(!!this.state.number.length) return false;
		return true;
	}

	handleErrorMessage = (statusMessage: string) => {
		this.setState({errorMessage: statusMessage, viewAd: true});
		
		setTimeout(() => {
			this.setState({ errorMessage: '',viewAd: false});
		}, 2000);
	}

	renderHeader = () => {
		return (
			<StyledHeader>
				<FaArrowLeft data-test-id="go-back" onClick={this.handleBack} />
				<Typography data-test-id="search_data" className="pageTitle" onClick={() => { }}>
					{this.getHeaderTitle()}
				</Typography>
				<FaSearch data-test-id="searchIcon" />
			</StyledHeader>
		);
	};

	render() {
		const { classes }: any = this.props;

		return (
			<Box style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}>
				{!(this.state.number === 'details') && <Toolbar>
            		{this.renderHeader()}
				</Toolbar>}
				{this.getSettingPageData() && <div className={classes.root}>
					<Typography variant="h6" className={classes.title}>
						ACCOUNT
					</Typography>
					<CustomDivider />
					<List component="nav" className={classes.list}>
						<ListItem button 
							className={classes.listItem} 
							onClick={() => this.handleChangeScreen('details')}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<PersonOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Personal details" />
						</ListItem>
						<ListItem 
							button 
							className={classes.listItem} 
							onClick={() => this.handleChangePassword()}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<LockOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Change password" />
						</ListItem>
						<ListItem 
							className={classes.listItem} 
							button 
							onClick={() => this.handleChangeScreen('email')}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<MailOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Change Email" />
						</ListItem>
					</List>
				</div>}
				{this.state.number === 'password' && (
					<ChangePassword {...this.props} 
						handlePageState={this.handleChangeScreen} 
						handleMessage={this.handleErrorMessage}
					/>
				)}
				{this.state.number === 'details' && (
					<PersonalDetails {...this.props} id="" 
						handlePageState={this.handleChangeScreen} 
						handleMessage={this.handleErrorMessage}
					/>
				)}
				{this.state.number === 'email' && (
					<ChangeEmail {...this.props} 
						inputEmail={this.state.emailInput} 
						handlePageState={this.handleChangeScreen} 
						handleMessage={this.handleErrorMessage}
					/>
				)}
				{this.state.viewAd &&
					<Box className={classes.successStatusWrapper} data-test-id="card_success">
						<Box className={classes.successStatus}>
							<img src={success_icon} /> {this.state?.errorMessage || ''}
						</Box>
					</Box>
				}
			</Box>
		);
	}
}

export default withStyles(styles)(Setting);

const StyledHeader = styled(AppBar)({
	width: '100%',
	display: "flex",
	flexDirection: 'row',
	justifyContent: "space-between",
	background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
	boxShadow: "none",
	alignItems: "centner",
	padding: "20px 16px 16px 20px",
	color: "#FFF",
	"& .pageTitle": {
		fontSize: "16px",
		fontWeight: 700,
		lineHeight: "22px",
	},
	borderBottom: "1px solid #64748B",
});

const CustomDivider = styled(Divider)({
  backgroundColor: "#334155",
  height: '1px',
})
