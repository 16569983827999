import React from "react";

import {
  Container,
  Box,
  // Customizable Area Start
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",

    },
  },
  typography: {
    h1: {
      fontWeight: 'bold',
      color: 'green',
    },
    h5: {
      color: "red",
      fontWeight: 600,

    },
    h6: {
      fontWeight: 500,
    },
    body2: {
      fontSize: '0.7em',
    },
    subtitle1: {
      margin: "20px 0px",
    }

  },
  overrides: {
    MuiButton: {
      root: {
        '&$disabled': {
          opacity: '0.5 !important'
        }
      },

    }
  }
});
import { imagea, imageb } from "./assets";
import { app_logo} from "../../email-account-login/src/assets";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";


export default class RecoveryMailSuccess extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
       <div style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',position:'fixed',width:'100vw',backgroundRepeat: 'no-repeat',backgroundSize: '100% 100%'}}>
        <Container maxWidth={"sm"} style={{height: '100vh',overflowY:'hidden'}}>
        <img src={imagea} alt="imagea" style={{position:'absolute',top:"0px",right:"-25px"}}></img>
          <img src={imageb} alt="imageb" style={{position:'absolute',bottom:0,left:0}}></img>
          <Box sx={webStyle.mainWrapper}>
            <img src={app_logo} alt="app_logo" style={webStyle.logo} />
            <Container style={{ padding: '0', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%',marginBottom:'80px'}}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ fontSize: '1.1em', color: '#FFFFFF', display: 'flex', fontWeight: '700', margin: '16 0 8', justifyContent: 'center' }}>Check your email</Box>
                <Box sx={{ fontSize: '1em', color: '#FFFFFF', fontWeight: '400', margin: '15px 0 8px 0', textAlign: 'center' }}>We have sent a password reset link to {this.state.emailValue}</Box>
                <div style={{ margin: '24 0', textAlign: 'center' }}>
                  <Button data-test-id="linkToResendEmail" onClick={this.reSendLink} variant="text" style={{ color: 'red', fontSize: '1em', fontWeight: 700, marginLeft: 3, textTransform: 'none' }}>
                    Resend e-mail
                  </Button>
                </div>
                <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'baseline' }}>
                  <span style={{ fontSize: '1em',color: '#E2E8F0' }}>Back to</span>
                  <Button data-test-id="linkToNavigate" onClick={this.naviagteToLogIn} variant="text" style={{ color: '#F8FAFC',background:'none',fontSize: '1em', textTransform:'lowercase',fontWeight: 700, marginLeft: 3 }}>
                  Log in
                  </Button>
                </div>
              </Box>

            </Container>
          </Box>

        </Container>
      </div>.
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  logo: {
    filter: 'invert(1)',
    marginTop: "80px",
  },
  mainWrapper: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    fontFamily: "Inter",
    alignItems: "center",
    height: '80%',

  },

};
// Customizable Area End
