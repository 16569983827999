import React from "react";
import {
  Drawer,
} from "@material-ui/core";
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { ChevronRight } from '@material-ui/icons';

import CardDetailsController from '../../blocks/stripepayments/src/CardDetailsController';
import StripePayments from "../../blocks/stripepayments/src/StripePayments.web";

export type CardDetailType = {id: string, type: string, attributes: any}
export type paymentIntentType = {[key: string]: any};
export type IInterFace = any;

export default class CollectPayment extends CardDetailsController {
  setCardDataDetails = (cardData: CardDetailType) => {
    cardData && this.setState({cardDataDetails: cardData})
  }

  handleMessage = (goTo: string) => {
      this.props.navigation.navigate(goTo)
  }

  render() {
    const { isPaymentDrawer, handlePaymentDrawer, data, navigation, handlePostCreation } = this.props;
    const { cardDetails, savedCardList ,saveStripeKay } = this.state;
    return (
      <Drawer anchor="bottom" open={isPaymentDrawer} onClose={handlePaymentDrawer} PaperProps={{ elevation: 0, style: {backgroundColor: "transparent" }}}>
          <StripePayments 
            {...this.props}
            navigation={{
              ...this.props.navigation,
              ...this.props,
              cardDataDetails: this.state.cardDataDetails,
              setCardDataDetails: this.setCardDataDetails,
              savedCardList: savedCardList,
              cardDetails: cardDetails,
              uploadScreenData: data,
              saveStripeKay: saveStripeKay,
              handleMessage: this.handleMessage,
              handlePostCreation: handlePostCreation,
            }}
          />
      </Drawer>
    );
  }
};

export const CardListComponent = (props: any) => {
  return (
    <>
      {Array.isArray(props.getCardList()) && props.getCardList().length < 8 ?
        (
        <ListItem key={`addcardbutton`} button data-test-id={`addCardBtn`} style={{ padding: '8 16', borderBottom: '1px solid #64748B' }}>
          <ListItemText
          style={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#fff' }}
          onClick={props.handleCardFormOpen}>
  
          <span style={{ padding: '0' }}>Add payment method</span>
          <br />
          </ListItemText>
          <ListItemIcon style={{ minWidth: '10px', color: '#CBD5E1' }}><ChevronRight /></ListItemIcon>
        </ListItem>
        ) : (
        <Box sx={webStyle.cardErrorBox} data-test-id="btnInvalidMethod">
          Your card limit is full. You cannot add more than 8 cards
        </Box>
        )
      }
      {props.cardSuccessMessage &&
        <Box sx={webStyle.successStatusWrapper} data-test-id="card_success">
          <Box sx={webStyle.successStatus}>
          <img src={props.success_icon} /> Card updated successfully
          </Box>
        </Box>
      }
    </>
  )
}

const webStyle = {
  cardErrorBox: {
		borderLeft: "4px solid #DC2626",
		fontSize: "12px",
		lineHeight: "18px",
		backgroundColor: "#FEE2E2",
		color: "#DC2626",
		padding: "15px 16px",
		margin: "15px 0",
		width: '100%',
		boxSizing: "border-box",
  },
  successStatusWrapper: {
    position: "absolute",
    bottom: "50px",
    width: "90%",
    display: "flex",
    justifyContent: "center",
    margin: 'auto'
  },
  successStatus: {
    maxWidth: "300px",
    padding: "10px",
    display: "flex",
    margin: "auto",
    color: "#0F172A",
    fontSize: "16px",
    lineHeight: "24px",
    position: 'fixed',
    bottom: "20px",
    width: '80%',
    borderRadius: "8px",
    backgroundColor: "#fff",
    '& img': {
      marginRight: "8px",
      width: "24px"
    },
    alignItems: 'center',
    gap: '10px',
  },
}