import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import {IInterFace} from '../../../components/src/CollectPayment'

export interface DashboardStates {
  title: string;
  key: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;

  // Customizable Area Start
  inputEmail?: string;
  handlePageState?: (data: string) => void;
  handleMessage?: (message: string) => void;
  // Customizable Area End
}

interface AdManagerData {
  idCount: string;
  type: string;
  attributes: {
    start_date: string;
    end_date: string;
    status: boolean;
    link: string;
    message: string;
    view_count: number;
    click_count: number;
    image: string;
  };
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currentImage: IInterFace;
  status: boolean;
  viewAd: boolean;
  number: IInterFace;
  arrayHolder: {
    name: string,
    amount: number | null,
    date: string,
    location: string,
    profile_photo: string,
  }[];
  emailInput?: string
  errorMessage?: string;
  isUpdateProfile?: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AdManagerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDashboardApiCallId: string = "";
  getTransactionApiCallId: string = "";
  getCountApiCallId: string = "";
  updateEmailApiCallId: string = "";

  responseMessage: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      arrayHolder: [],
      currentImage: 0,
      status: true,
      viewAd: false,
      number: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getListRequest();
    setInterval(() => {
      this.intervalShow();
      this.getListRequest();
    }, 30000);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        this.handleResponseMessage(apiRequestCallId, responseJson)
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  apiCall = async (data: {
    contentType: string;
    method: string;
    endPoint: string;
    bodyData?: {[key: string]: string | number}
  }) => {
    const { contentType, method, endPoint } = data;
    const token = await getStorageData("token");

    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  handleResponseMessage = (apiRequestCallId: string, responseJson: IInterFace) => {
    if (apiRequestCallId === this.getDashboardApiCallId) {
      this.handleDashboardResponseData(responseJson)
    }
    if (apiRequestCallId === this.getTransactionApiCallId) {
      if (!responseJson.errors) {            
        this.setState({ arrayHolder: responseJson.data });
      }
    }
    if (apiRequestCallId === this.updateEmailApiCallId) {
      this.handleUpdateEmailResponseData(responseJson)
    }
  }

  handleDashboardResponseData = (responseJson: IInterFace) => {
    if (!responseJson.errors) {            
      this.setState({ currentImage: responseJson });
    } else {
      this.props.navigation('HomeDashboard');
    }
  }

  handleUpdateEmailResponseData = (responseJson: IInterFace) => {
    if (!responseJson.errors) {            
      this.responseMessage = 'Email successfully changed';
    } else {
      this.props.navigation('HomeDashboard');
    }
  }
  
  getListRequest = async (selectedFilter: string = configJSON.filterList[0].key) => {
    if(this.state.txtInputValue === "" && this.state.txtSavedValue === "A") {
      this.setState({
        txtInputValue: configJSON.filterList[0].key,
        txtSavedValue: configJSON.filterList[0].title
      })
    }
    this.getDashboardApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.exampleAPiEndPoint}?range=${selectedFilter}`
    });
    this.intervalShow()
  };

  intervalShow = async () => {
    this.getTransactionApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.transactionAPiEndPoint
    });
  };

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      number: event.currentTarget
    })
  };

  handleMenuClose = () => {
    this.setState({
      number: ''
    })
  };

  selectFilter = (filterData: DashboardStates) => {
    this.setState({txtSavedValue: filterData.title, txtInputValue: filterData.key})
    this.getListRequest(filterData.key);
  }

  getDashboardState = (dataKey: string): number => {
    if(this.state.currentImage === 0) return 0
    else return this.state.currentImage[dataKey]
  }

  // Customizable Area End
}
