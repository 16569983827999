export const onBoardingOne = require("../assets/Onboarding_One.png");
export const onBoardingTwo = require("../assets/Onboarding_Two.png");
export const onboardingThree = require("../assets/Onboarding_Three.png");
export const onboardingFour = require("../assets/Onboarding_Four.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const onBoarding_one = require("../assets/tutorail_0.png");
export const onBoarding_two = require("../assets/tutorial_1.png");
export const onboarding_three = require("../assets/tutorial_2.png");
export const onboarding_four = require("../assets/Onboarding_Four.png");
export const onboarding_five = require("../assets/back3.png");
export const backimage_one = require("../assets/back1.png");
export const backimage_two = require("../assets/back2.png");
export const backimage_three = require("../assets/back3.png");
export const backimage_four = require("../assets/back4.png");
export const backimage_five = require("../assets/back3.png");
