import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { ArrowBack } from "@material-ui/icons";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
          <Grid container spacing={1} style={{margin:'8px -8px',borderBottom:'1px solid #E2E8F0' }}>
        <Grid item xs style={{padding:'4px 8px 10px',flexGrow:0}}>
          <IconButton data-test-id="backArrowBtn" onClick={this.navigateBack}>
            <ArrowBack style={{height:'24px',width:'24px'}}/>
          </IconButton>
        </Grid>
          <Grid item sm xs={8} style={{padding:'4px 8px 10px',display:'grid',alignItems:'center'}}>
            <Typography style={{textTransform:'uppercase',fontSize:'0.8em',fontWeight:700,textAlign:'center'}}>{this.state.txtSavedValue}</Typography>
          </Grid>
          <Grid item xs container style={{justifyItems:'center',padding:'4 8 24',display:'grid'}}>
          </Grid>
        </Grid>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{this.state.txtSavedValue}</Typography>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
