Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "HamburgerMenu";
exports.labelBodyText = "HamburgerMenu Body";

exports.btnExampleTitle = "CLICK ME";
exports.Calender = "Calender"
exports.manageyourpersonalcalender = "Manage your personal calender"
exports.messageandCalls = "Message & Calls"
exports.talktoyourconnections = "Talk to your connections"
exports.companiespage = "Companies Page"
exports.viewyourcmpanypage = "View/Manage your companies page"
exports.referenceLabel = "References"
exports.manageReferences = "Manage references for job applications"
exports.settings = "Settings"
exports.settingNote = "Tune-in the pello"
exports.logout = "Logout",
  exports.myJobLabel = "My Jobs"
exports.viewyourjobApplications = "View your Job Applications"

exports.menu = "Menu"
exports.close = "Close"
exports.drawerMenu = [{pageName:'Terms & Conditions',pagePath:'Termsandconditions'},{pageName:'Privacy Policy',pagePath:'PrivacyPolicy'},{pageName:"FAQ's",pagePath:'Settings2'},{pageName:'Guides & Information',pagePath:'Settings2'},{pageName:'Referral Program',pagePath:'Settings2'},{pageName:'Legal',pagePath:'Settings2'},{pageName:'Help',pagePath:'Settings2'}]
exports.drawerMenuScreenManager = [{pageName:'Settings',pagePath:'Settings2', noArrow: true},{pageName:'Help',pagePath:'Settings2', noArrow: true}, {pageName:'Logout',pagePath:'Logout', noArrow: true}]

exports.user = ['home', 'screens', 'files', 'settings']
exports.screenManager = ['manager_home', 'screens', 'search', 'user']
exports.admin = ['manager_home', 'screens', 'files', 'settings']
exports.superAdmin = ['manager_home', 'screens', 'files', 'settings']
// Customizable Area End