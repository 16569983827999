Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "signuplogin";
exports.labelBodyText = "signuplogin Body";

exports.getScreensApiEndPoint = 'bx_block_custom_form/screens/'
exports.btnExampleTitle = "CLICK ME";
exports.drawerMenu =['Terms & Conditions','Privacy Policy',"FAQ's",'Guides & Information','Referral Program','Legal','Help']
exports.signUpApiEndPoint = '/account_block/accounts'
exports.termsError = 'You have to agree with Privacy Policy and Terms and Conditions to Sign up.'
// Customizable Area End