import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  Drawer,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides:{
    MuiDrawer:{
      root:{
        '&.noInternetAlert > .MuiPaper-root':{
          // backgroundColor:'red',
          // height:'45%',
          borderTopRightRadius:'25px',
        },
        
      }
      
    },
    MuiButton: {
      root: {
        fontSize: '0.8em',
        textTransform: 'none',
        borderRadius:'8px',
        margin: '12 0 0',
        '&.tryAgainBtn': {
          color: 'white',
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
          padding:'12',
          fontWeight: '700',
          marginTop:'32px'
        },
      }
    }
  }
});
import { noInternet, splashImage, splashLogo } from "./assets";
// Customizable Area End

import Splashscreen1Controller, {
  Props,
  configJSON,
} from "./Splashscreen1Controller";

export default class Splashscreen1 extends Splashscreen1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
         <Container maxWidth={"sm"} style={{backgroundImage:`url(${splashImage})`,backgroundSize:'100% 100%', backgroundRepeat:'no-repeat',height:'100vh',display:'flex',justifyContent:'center',alignContent:'center'}}>
          <Box sx={webStyle.mainWrapper}>
          <img src={splashLogo} alt="app_logo"/>  
          <CircularProgress color="primary"  style={{margin:'43px 0px 20px'}}/>          
            {/* <Typography variant="h6">{configJSON.labelTitleText}</Typography> */}
            <Typography variant="subtitle1" component="div" style={{color:'white',fontSize:'12px'}}>
              {configJSON.loaderText}
            </Typography>
           
          </Box>
          <Drawer data-test-id="no-internet-alert" className="noInternetAlert" anchor={'bottom'} open={this.state.isDisconnected} onClose={() => this.closeAlert()}>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button variant="text" data-test-id="closeBtn" onClick={() => this.closeAlert()} style={{ margin: '20px 3px', padding: '0px' }}><Close style={{ fontSize: '2em' }} /></Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '0px 24px 24px' }}>
              <div style={{ width: 56, height: 56, backgroundColor: '#FEE2E2', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '200px' }}>
                <img src={noInternet} alt="No Internet" />
              </div>
              <Typography variant="subtitle1" component="div" style={{ fontSize: '1.6em', fontWeight: 700, margin: '12 0 0', color:'#0F172A' }}>
                Oops! No internet connection
              </Typography>
              <Typography variant="subtitle1" component="div" style={{ fontSize: '0.92em', fontWeight: 400, margin: '8 0', color:'#0F172A' }}>
                Make sure wi-fi or cellular data is turned on and then please try again.
              </Typography>
              <Button
            data-test-id="tryAgainBtn"
            className={"tryAgainBtn"}
            fullWidth
            variant="contained"
            onClick={this.refreshPage}
          >
            Try again
          </Button>
            </Box>
          </Drawer>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    justifyContent:'center'
    // background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
