import React from 'react';
import { styled, Container, Box, Button, Typography, Paper } from '@material-ui/core';
import { GoHomeFill } from "react-icons/go";
import CardDetailsController from '../../blocks/stripepayments/src/CardDetailsController';
import {payment_success, payment_failed} from '../../blocks/stripepayments/src/assets';


const classes = {
    root: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
        overflow: 'scroll',
        paddingBottom: '100px'
    },
    paper: {
        'text-align': 'center',
        background: 'transparent',
        boxShadow: 'none',
        margin: 'auto',
        marginTop: '40%'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px',
    },
    image: {
        margin: 'auto',
        width:'100%',
    },
    header: {
        color: '#F1F5F9',
        fontSize: '20px',
        fontWeight: 'bold',
    },
    description: {
        color: '#CBD5E1',
        fontSize: '16px',
    },
    goHomeIcon: {
        zIndex: 9,
        top: 20,
        left: 20,
        display: 'flex',
        color: '#FFF',
    }, 
    bottomButtonStyle: {
        width: '100%', bottom: '24px', padding: '0px 20px', maxWidth: 'calc(100% - 40px)'
    }
};

const GoBackToScreenButton = styled(Button)({
    padding: '16px 16px',
    borderRadius: '8px',
    background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'capitalize',
    width: '100%',
})


class PaymentSuccess extends CardDetailsController {
    render() {
        return (
            <Container style={classes.root}>
                <div style={{...classes.goHomeIcon, position: 'absolute', flexDirection: 'column',}}>
                    <GoHomeFill 
                        data-test-id="home_icon" 
                        size={34} 
                        style={{color: '#FFF'}} 
                        color="white"
                        name='home'
                        onClick={this.handleDashboard}
                    />
                    <span style={{fontSize: '12px'}}>Home</span>
                </div>
                <Paper style={classes.paper}>
                    <Box style={classes.imageContainer}>
                        <img src={this.getScreenType() === '/PaymentFailed' ? payment_failed : payment_success} alt={this.getScreenType() === '/PaymentFailed' ? "Payment Failed" : "Payment Success"} style={classes.image} />
                    </Box>
                    <Typography variant="h6" style={classes.header} gutterBottom>
                        {this.getScreenType() === '/PaymentFailed' ? "Payment Failed" : "Payment Success"}
                    </Typography>
                    <Typography variant="body1" style={classes.description} gutterBottom>
                        {this.getScreenType() === '/PaymentFailed' ? `Don't worry your money is safe. if money was debited from your account, it will be refunded automatically in 5-7 working days.`
                            :
                            `Thank you for your order. Your files are under review. We will notify once it is approved via email.
                            You can also find your Order History within your Profile.`}
                    </Typography>
                    <div style={{...classes.bottomButtonStyle, boxSizing: 'border-box', position: 'fixed'}}>
                        <GoBackToScreenButton
                            fullWidth
                            data-test-id="go_to_files"
                            variant="contained"
                            onClick={this.handleGoToFiles}
                        >
                            View files
                        </GoBackToScreenButton>
                    </div>
                </Paper>
            </Container>
        );
    }
}

export default PaymentSuccess;