export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const deleteIcon = require("../assets/delete_image.png");
export const historyIcon = require("../assets/history_image.png");
export const personIcon = require("../assets/person_image.png");
export const lockIcon = require("../assets/lock_image.png");
export const cardIcon = require("../assets/card_icon.png");
export const dollarIcon = require("../assets/dollar_image.png");
export const mailIcon = require("../assets/mail_image.png");
export const logoutIcon = require("../assets/logout_image.png");
