import React from "react";

import {
  Container,
  Box,
  Button,
  Dialog,
  Typography,
  IconButton,
  // Customizable Area Start

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: 'bold',
      color: 'green'
    },
    h5: {
      fontWeight: 600,
      color: "red"
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    body2: {
      fontSize: '0.7em',
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        '@media (max-height: 700px)': {
          height: '100% !important',
        },
        '@media (min-width: 550px) and (min-height: 700px)': {
          height: '100% !important'
        },
        '&.MuiContainer-root-1 > div > img': {
          '@media (min-width: 400px)': {
            width: '70% !important',
          },
          '@media (min-width: 500px)': {
            width: '60% !important',
          },
          '@media (min-width: 600px)': {
            width: '50% !important',

          },

        }
      },
    },
    MuiButton: {
      root: {
        '&.scanBtn': {
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
          fontWeight: '700',
          color: 'white',
          marginTop: '8',
          marginBottom: '10% !important',
        },

        '&.uploadBtn': {
          fontWeight: '700',
          color: '#0F172A',
          marginTop: '32'
        },
        fontSize: '1em',
        textTransform: 'none',
        marginBottom: '12px',
        borderRadius: '8px',
        padding: '0.8em 1em !important'
      }
    }
  },
});
import { home_screen, imgBase, qrImg } from "./assets";
// Customizable Area End

import HomeDashboardController, {
  Props,
} from "./HomeDashboardController";
import BottomNavigationBar from "../../utilities/src/BottomNavigationBar.web";


export default class HomeDashboard extends HomeDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { open } = this.state
    return (
      // Customizable Area Start
      <BottomNavigationBar navigation={this.props.navigation} id={"bottomNavigation"} fromPage="home">
        <ThemeProvider theme={theme}>
          <Container maxWidth={"md"} style={{ backgroundColor: '#F1F5F9', height: '92%' ,overflowY:'hidden'}}>

            <Box sx={webStyle.mainWrapper}>

              <img src={home_screen} alt="home_screen_logo" style={{ width: '80%', marginTop: '25%' }} />
              <Button
                data-test-id="uploadBtn"
                fullWidth
                variant="outlined"
                className="uploadBtn"
                onClick={() => this.navigateToScreen('PaymentScreen')}
              >
                Upload To Screen
              </Button>
              <Button
                data-test-id="scanQRBtn"
                fullWidth
                variant="contained"
                className="scanBtn"
                onClick={this.handleClickOpen}
              >
                Scan QR Code
              </Button>
            </Box>
          </Container>
          <Dialog
            fullScreen
            open={open}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: {
                maxHeight: '80%',
                height: '80%',
                borderTopRightRadius: '20px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                background: 'var(--overlay, rgba(1, 1, 1, 0.80))',
              },
            }}
          >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', borderBottom: '1px solid var(--Neutrals-Cool-gray-500, #64748B)' }}>
                <Typography variant='h5' style={{ color: 'var(--Neutrals-Cool-gray-50, #F8FAFC)', fontWeight: '700', padding: '4px 10px 10px 10px' }}>QR code scanning</Typography>
                <IconButton aria-label="close" onClick={this.handleClose}>
                  <CloseIcon style={{ color: "#F8FAFC" }} />
                </IconButton>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                <div style={{ width: "70%", paddingTop: "70%", borderRadius: "50%", backgroundColor: '#2D2E31', position: 'relative', overflow: "hidden", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{  position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: 'center' }}>
                    {/* <div style={{ width: '100px', height: '100px', borderRadius: '50%', backgroundColor: '#2D2E31' }}></div>
                    <div style={{ position: 'absolute', width: '280px', height: '280px', borderRadius: '50%', backgroundColor: '#2D2E31' }}></div> */}
                    <img src={qrImg} alt="QR Code" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  </div>
                </div>
              </div>

              <div style={{ padding: '20px' }}>
                <Typography variant="body1" gutterBottom style={{ lineHeight: '1.5', color: '#fff' }}>
                  Make sure to enable access to your device's camera when requested so that you are able to scan the QR code
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '0 10px 0px 10px' }}>
              <Button
                style={{ display: 'flex', alignItems: 'center', fontSize: '16px', color: 'white', fontWeight: '700' }}
                onClick={this.handleClose}
              >
                Cancel
              </Button>
            </div>
            <div style={{ padding: '0 10px  10px 10px' }}>
              <Button style={{ height: '55px', borderRadius: '8px', background: 'linear-gradient(175deg, #FFF 4.52%, #919191 103.95%)', color: 'black', fontWeight: '700', fontSize: '16px' }} fullWidth variant="contained"
                onClick={() => this.navigateToScreen('QRCodeScanner')}
                data-test-id="qrContinueBtn"

              >
                Continue
              </Button>
            </div>
          </Dialog>
        </ThemeProvider>
      </BottomNavigationBar>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "17%",
    '@media (max-height:700px)': {
      marginBottom:'20%'
    },
  }
};
// Customizable Area End
